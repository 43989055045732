import { graphql } from "gatsby";
import OurAdvisors from "../../templates/ourAdvisors";
import links from "/src/scripts/aboutLinks";
const OurAdvisorsPage = ({
	data: {
		craft: { entry },
	},
}) => <OurAdvisors entry={entry} subLinks={links} />;

export const pageQuery = graphql`
	query OurAdvisorsPage {
		craft {
			entry(slug: "our-board") {
				... on CraftCMS_ourAdvisors_ourAdvisors_Entry {
					heroTitle
					heroText
					pageContentRows {
						... on CraftCMS_pageContentRows_pageContentRowsItem_BlockType {
							pageContentRowsItemTitle
							pageContentRowsItemText
							pageContentRowsItemImage {
								width
								height
								url
								title
								imagerX(transform: "maxWidth-1920") {
									width
									height
									url
								}
								imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
									width
									height
									url
								}
							}
						}
					}
					metaTitle
					metaDescription
					metaShareImage {
						filename
						url
					}
				}
			}
		}
	}
`;

export default OurAdvisorsPage;
