import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroGeneric from "../components/HeroGeneric";
import LeftyRighty from "../components/LeftyRighty";

export default function OurAdvisors({ entry, breadcrumb, subLinks }) {
	const {
		heroTitle,
		heroText,
		pageContentRows,
		metaTitle,
		metaDescription,
		metaShareImage,
	} = entry ?? [];

	return (
		<Layout className="layout--ourAdvisors">
			<SEO
				title={metaTitle}
				description={metaDescription}
				image={metaShareImage}
			/>

			<HeroGeneric
				title={heroTitle}
				text={heroText}
				breadcrumb={breadcrumb}
				subLinks={subLinks}
				colorTheme="dark"
				nextTheme="light"
			/>

			<LeftyRighty items={pageContentRows} reverse={true} />
		</Layout>
	);
}
